import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../Globals';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  ApiURL = environment.ApiURL;
  // public headers: HttpHeaders;

  constructor(private http: HttpClient, private globals: Globals) {}

  getMenu(): Observable<any> {
    return this.http.get(
      this.ApiURL + 'Permission/GetMenuList?lang=' + this.globals.getLanguage(),
      {
        headers: this.header(),
      }
    );
  }

  header() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.globals.getAccessToken(),
      Lang: this.globals.getLanguage(),
    });
  }
}
