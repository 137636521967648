import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { Globals } from '../Globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(
    public _firebaseAuth: AngularFireAuth,
    public router: Router,
    public toastr: ToastrService,
    private globals: Globals
  ) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe((user) => {
      if (user) {
        this.userDetails = user;
      } else {
        this.userDetails = null;
      }
    });
  }

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    // your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    // uncomment above firebase auth code and remove this temp code
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(true);
      }, 1000);
    });
  }

  // check if user is authenticated or not through token
  isAuthenticated() {
    let isValidToken = false;
    try {
      const token = this.globals.getAccessToken();
      if (token !== '') {
        if (!this.jwtHelper.isTokenExpired(token)) {
          isValidToken = true;
        }
      }
    } catch (error) {
      console.log('Auth service => isAuthenticated error : ');
      console.log(error);
    }
    return isValidToken;
  }

  // Check URL is authorised or Not, getUserRolePermissions
  isAuthorised(path: string): boolean {
    let allowAccess = true;
    const menus: any[] = JSON.parse(
      localStorage.getItem('permittedMenu') || '[]'
    );
    try {
      if (menus && menus.filter((f) => f.routes === path).length === 0) {
        allowAccess = false;
      }
    } catch (error) {
      console.log('Auth service => isAuthorised error : ');
      console.log(error);
    }
    return allowAccess;
  }

  getRoleName(): string {
    let roleName = '';
    try {
      const token = this.globals.getAccessToken();
      if (token !== '') {
        this.decodedToken = this.jwtHelper.decodeToken(token);
        if (this.decodedToken) {
          roleName =
            this.decodedToken[
              'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        }
      }
    } catch (error) {
      this.toastr.error(error);
    }
    return roleName;
  }

  getUserName(): string {
    let userName = '';
    try {
      const token = this.globals.getAccessToken();
      if (token !== '') {
        this.decodedToken = this.jwtHelper.decodeToken(token);
        if (this.decodedToken) {
          userName = this.decodedToken['Username'];
        }
      }
    } catch (error) {
      this.toastr.error(error);
    }
    return userName;
  }
  logout() {
    this.globals.redirectToLogin();
    this._firebaseAuth.signOut();
  }
}
