import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'app/shared/Globals';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { User } from '../_models/administration';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  ApiURL = environment.ApiURL;
  constructor(private http: HttpClient, private globals: Globals) {}

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.globals.getAccessToken(),
      Lang: this.globals.getLanguage(),
    });
  }

  getUserById(): Observable<any> {
    return this.http.get(this.ApiURL + 'Profile', {
      headers: this.getHeaders(),
    });
  }

  editUser(userData: User): Observable<any> {
    const body = JSON.stringify(userData);
    return this.http.put(this.ApiURL + 'Profile', body, {
      headers: this.getHeaders(),
    });
  }

  changePassword(data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(this.ApiURL + 'Profile/ChangePassword', body, {
      headers: this.getHeaders(),
    });
  }

  getThemePreference(): Observable<any> {
    return this.http.get(this.ApiURL + 'Theme', {
      headers: this.getHeaders(),
    });
  }

  saveThemePreference(theme: any): Observable<any> {
    const body = JSON.stringify(theme);
    return this.http.post(this.ApiURL + 'Theme', body, {
      headers: this.getHeaders(),
    });
  }
}
