export const TRANSLATE_FILE = {
  English: {
    Dashboard: 'dashboard_en',
    Permission: 'permission_en',
    IconMaster: 'iconmaster_en',
    CheckBook: 'checkbook_en',
    Role: 'role_en',
    User: 'user_en',
    En: 'navbar_en',
    Profile: 'profile_en',
  },
  Arabic: {
    Dashboard: 'dashboard_ar',
    Permission: 'permission_ar',
    IconMaster: 'iconmaster_ar',
    CheckBook: 'checkbook_ar',
    Role: 'role_ar',
    User: 'user_ar',
    Ar: 'navbar_ar',
    Profile: 'profile_ar',
  },
};
