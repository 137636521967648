<div class="wrapper" dir="ltr">
    <div class="main-panel">
        <div class="">
            <div class="content-overlay"></div>
            <div class="content-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>

        <!-- Scroll to top button -->
        <button class="btn btn-primary scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()"><i class="ft-arrow-up"></i></button>
    </div>

    <!-- *CUSTOMIZER* starts -- Uncomment below code if you want to use customizer in your project -->
    <!-- <app-customizer></app-customizer> -->
    <!-- *CUSTOMIZER* ends -->

    <div class="sidenav-overlay">
    </div>
    <div class="drag-target"></div>
</div>