import {
  Component,
  Inject,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
import { Globals } from './shared/Globals';
import * as GlobalEnum from './shared/Globals';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from './shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private _hubConnection: HubConnection;
  subscription: Subscription;
  translateInfo = environment.config;
  constructor(
    public translate: TranslateService,
    private router: Router,
    private globals: Globals,
    private authService: AuthService,
    public toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    // this.signalRConnection();
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));

    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          // --- nothing to write
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          // -- translate language -- //
          const routeUrl = event.url;
          const currentlang = this.globals.getLanguage();

          const htmlTag = this.document.getElementsByTagName(
            'html'
          )[0] as HTMLHtmlElement;
          htmlTag.dir =
            currentlang === GlobalEnum.languages.Arabic.toString()
              ? 'rtl'
              : 'ltr';
          htmlTag.lang = currentlang;
          if (event instanceof NavigationCancel) {
            this.translate.setDefaultLang(this.translateInfo.English.Dashboard);
          } else if (routeUrl === '/dashboard') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.Dashboard);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.Dashboard);
            }
          } else if (routeUrl === '/dashboard/assets') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.Dashboard);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.Dashboard);
            }
          } else if (routeUrl === '/dashboard/liabilities') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.Dashboard);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.Dashboard);
            }
          } else if (routeUrl === '/dashboard/country') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.Dashboard);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.Dashboard);
            }
          } else if (routeUrl === '/dashboard/bank') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.Dashboard);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.Dashboard);
            }
          } else if (routeUrl === '/dashboard/currency') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.Dashboard);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.Dashboard);
            }
          } else if (routeUrl === '/administration/user') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.User);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.User);
            }
          } else if (routeUrl.includes('/administration/edituser')) {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.User);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.User);
            }
          } else if (routeUrl === '/administration/role') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.Role);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.Role);
            }
          } else if (routeUrl === '/administration/permission') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(
                this.translateInfo.Arabic.Permission
              );
            } else {
              this.translate.setDefaultLang(
                this.translateInfo.English.Permission
              );
            }
          }  else if (routeUrl === '/iconmaster') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(
                this.translateInfo.Arabic.IconMaster
              );
            } else {
              this.translate.setDefaultLang(
                this.translateInfo.English.IconMaster
              );
            }
          } else if (routeUrl === '/checkbooks') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(
                this.translateInfo.Arabic.CheckBook
              );
            } else {
              this.translate.setDefaultLang(
                this.translateInfo.English.CheckBook
              );
            }
          } else if (routeUrl === '/profile') {
            if (currentlang === GlobalEnum.languages.Arabic.toString()) {
              this.translate.setDefaultLang(this.translateInfo.Arabic.Profile);
            } else {
              this.translate.setDefaultLang(this.translateInfo.English.Profile);
            }
          } else {
            this.translate.setDefaultLang(this.translateInfo.English.Dashboard);
          }
          // -------------------------
        }
      },
      () => {}
    );
  }

  // signalRConnection() {
  //   this._hubConnection = new HubConnectionBuilder()
  //     .withUrl(`${environment.ApiURL}CashAppHub`)
  //     .build();

  //   this._hubConnection.on('logout', (userName, refreshToken) => {
  //     if (
  //       this.globals.getUserName() &&
  //       userName === this.globals.getUserName() &&
  //       refreshToken !== this.globals.getRefreshToken()
  //     ) {
  //       this.toastr.info('Your account logged from another browser.');
  //       this.authService.logout();
  //     }
  //   });

  //   Object.defineProperty(WebSocket, 'OPEN', { value: 1 });
  //   this._hubConnection
  //     .start()
  //     .then(() => console.log('Now connected'))
  //     .catch((err) =>
  //       console.log('Error while establishing signalR connection: ' + err)
  //     );
  // }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
