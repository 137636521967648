// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { TRANSLATE_FILE } from 'app/shared/services/translation-config';

export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  config: TRANSLATE_FILE,
  project: 'Cash App',
  // ApiURL: 'http://localhost:64200/api/',
  ApiURL: '/api/',
  currencyIconUrl: 'http://localhost:64200/Upload/CurrencyIcon/Currency_',
  countryIconUrl: 'http://localhost:64200/Upload/CountryIcon/Country_',
  bankIconUrl: 'http://localhost:64200/Upload/BankIcon/Bank_',
};
