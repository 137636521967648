import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Globals } from '../Globals';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private global: Globals
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuth = localStorage.getItem('userToken');
    if (localStorage.getItem('userToken')) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }

    // const path = state.url;
    // if (this.authService.isAuthenticated()) {
    //   if (this.authService.isAuthorised(path) === true) {
    //     return true;
    //   } else {
    //     console.log("1");
    //     this.router.navigate(["/unauthorized"]);
    //     return false;
    //   }
    //   // -----------------------------------------------
    // } else {
    //   this.global.clearLocalStorage();
    //   this.router.navigate(["auth/login"]);
    //   return false;
    // }
  }
}
