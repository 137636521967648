import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'app/shared/Globals';
import { environment } from 'environments/environment';
import { DashboardAssLiaBalance, DashboardCBCList } from '../_models/dashboard';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  ApiURL = environment.ApiURL;

  constructor(private http: HttpClient, private globals: Globals) { }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.globals.getAccessToken(),
      Lang: this.globals.getLanguage(),
    });
  }

  getCurrencyPreference(): Observable<any> {
    return this.http.get<any>(this.ApiURL + 'Profile/GetCurrencyPreference', {
      headers: this.getHeaders(),
    });
  }

  getDashboardAssLiaBalance(accountType): Observable<DashboardAssLiaBalance> {
    return this.http.get<DashboardAssLiaBalance>(
      this.ApiURL + 'Dashboard/GetBalance?accountType=' + accountType,
      {
        headers: this.getHeaders(),
      }
    );
  }

  getDashboardSummary(data): Observable<DashboardCBCList[]> {
    const body = JSON.stringify(data);
    return this.http.post<DashboardCBCList[]>(
      this.ApiURL + 'Dashboard/GetSummary',
      body,
      {
        headers: this.getHeaders(),
      }
    );
  }

  getDashboardSummaryDetails(data): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post<any>(
      this.ApiURL + 'Dashboard/GetSummaryDetails',
      body,
      {
        headers: this.getHeaders(),
      }
    );
  }

  getDashboardAssets(accountType): Observable<DashboardCBCList[]> {
    return this.http.get<DashboardCBCList[]>(
      this.ApiURL + 'Dashboard/GetAssets?accountType=' + accountType,
      {
        headers: this.getHeaders(),
      }
    );
  }

  getDashboardLiabilities(accountType): Observable<DashboardCBCList[]> {
    return this.http.get<DashboardCBCList[]>(
      this.ApiURL + 'Dashboard/GetLiabilities?accountType=' + accountType,
      {
        headers: this.getHeaders(),
      }
    );
  }

  getExchangeRate(): Observable<any> {
    return this.http.get(
      this.ApiURL +
      'Dashboard/GetExchangeRate?currency=' +
      this.globals.getCurrencyPreference(),
      {
        headers: this.getHeaders(),
      }
    );
  }
}
