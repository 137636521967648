import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../pages/admin/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'iconmaster',
    loadChildren: () =>
      import('../../pages/admin/icon-master/icon-master.module').then(
        (m) => m.IconMasterModule
      ),
  },
  {
    path: 'checkbooks',
    loadChildren: () =>
      import('../../pages/admin/checkbook/checkbook.module').then(
        (m) => m.CheckbookModule
      ),
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('../../pages/admin/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('../../pages/profile/profile.module').then((m) => m.ProfileModule),
  },
];
