import { Routes, RouterModule } from '@angular/router';

export const DEFAULT_ROUTES: Routes = [
  {
    path: 'print',
    loadChildren: () =>
      import('../../pages/default/default-pages.module').then(
        (m) => m.DefaultPagesModule
      ),
  },
];
