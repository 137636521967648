import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

// --- ngx-boostrap bsdatepicker ---------
export const colorTheme = 'theme-blue';

export const bsDatePickerConfig: any = {
  containerClass: colorTheme,
  dateInputFormat: 'DD/MM/YYYY',
  isAnimated: true,
};
// ---------------------------------------

export enum AutoIncrement {}

export enum languages {
  English = 'en',
  Arabic = 'ar',
}

// --- sort table list
export function sortList(
  columnName: string,
  columnType: string,
  isDesc: boolean,
  tempList: any[]
): any[] {
  const direction = isDesc ? 1 : -1;
  const temp = tempList.sort(function (a, b) {
    if (columnType === 'string') {
      if (a[columnName] < b[columnName]) {
        return -1 * direction;
      } else if (a[columnName] > b[columnName]) {
        return 1 * direction;
      } else {
        return 0;
      }
    } else {
      const first = Number(a[columnName]);
      const second = Number(b[columnName]);
      if (first < second) {
        return -1 * direction;
      } else if (first > second) {
        return 1 * direction;
      } else {
        return 0;
      }
    }
  });
  tempList = temp;
  return tempList;
}

export function convertToDateTime(dateTime: Date): any {
  if (dateTime) {
    const date =
      dateTime.getFullYear() +
      '-' +
      ('0' + (dateTime.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dateTime.getDate()).slice(-2) +
      'T' +
      dateTime.getHours() +
      ':' +
      ('0' + dateTime.getMinutes()).slice(-2) +
      ':' +
      dateTime.getSeconds();
    return date;
  }
}
export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid
    ? null
    : {
        isValid: {
          valid: false,
        },
      };
};
@Injectable()
export class Globals {
  sessionExpiredMsg = 'Your Session is expired, login again!';
  errorMsg = 'Something went wrong.';
  public headers: HttpHeaders;

  constructor(public router: Router) {}

  // ----- Local Storage -----------------------------
  setAccessToken(token: any) {
    localStorage.setItem('userToken', token);
  }

  getAccessToken(): any {
    let token = '';
    if (localStorage.getItem('userToken')) {
      token = localStorage.getItem('userToken');
    }
    return token;
  }

  setRefreshToken(token: any) {
    localStorage.setItem('refreshToken', token);
  }

  getRefreshToken(): any {
    let token = '';
    if (localStorage.getItem('refreshToken')) {
      token = localStorage.getItem('refreshToken');
    }
    return token;
  }

  setUserName(userName: any) {
    localStorage.setItem('userName', userName);
  }

  getUserName(): any {
    let userName = '';
    if (localStorage.getItem('userName')) {
      userName = localStorage.getItem('userName');
    }
    return userName;
  }

  setGraphType(graphType) {
    localStorage.setItem('graphType', graphType);
  }

  getGraphType(): any {
    let data = '';
    if (localStorage.getItem('graphType')) {
      data = localStorage.getItem('graphType');
    }
    return data;
  }

  setAccountType(AccountType) {
    localStorage.setItem('accountType', AccountType);
  }

  getAccountType(): any {
    let data = '';
    if (localStorage.getItem('accountType')) {
      data = localStorage.getItem('accountType');
    }
    return data;
  }

  setCurrencyRate(rate) {
    localStorage.setItem('currencyRate', rate);
  }

  getCurrencyRate(): any {
    let data = '';
    if (localStorage.getItem('currencyRate')) {
      data = localStorage.getItem('currencyRate');
    }
    return data;
  }

  setCurrencyPreference(color: any) {
    localStorage.setItem('currencyPreference', color);
  }

  getCurrencyPreference(): any {
    let userName = '';
    if (localStorage.getItem('currencyPreference')) {
      userName = localStorage.getItem('currencyPreference');
    }
    return userName;
  }

  setAssignedMenu(menus: any) {
    localStorage.setItem('permittedMenu', JSON.stringify(menus));
  }

  getAssignedMenu(): any {
    let menus = '';
    if (localStorage.getItem('permittedMenu')) {
      menus = localStorage.getItem('permittedMenu');
    }
    return menus;
  }

  setLanguage(language: string) {
    localStorage.setItem('lang', language);
  }

  getLanguage(): string {
    let language = '';
    if (localStorage.getItem('lang')) {
      language = localStorage.getItem('lang');
    }
    return language;
  }
  // -------------------------------------------------------

  clearLocalStorage() {
    localStorage.removeItem('userName');
    localStorage.removeItem('userToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('permittedMenu');
    localStorage.removeItem('lang');
    localStorage.clear();
  }

  redirectToLogin() {
    this.clearLocalStorage();
    setTimeout(() => {
      // this.loading = false;
      // this.router.navigate(["/auth/login"]);
      window.location.href = '/auth/login';
    }, 1500);
  }
  // header
  header() {
    return (this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getAccessToken(),
      Lang: this.getLanguage(),
    }));
  }

  // header for file
  fileHeader() {
    return (this.headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.getAccessToken(),
      Lang: this.getLanguage(),
    }));
  }
}
