import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpResponse, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "environments/environment";
import { Globals } from "../shared/Globals";
import { AuthService } from "../shared/auth/auth.service";
import { tap, mergeMap, switchMap, filter, take, flatMap, catchError } from 'rxjs/operators';
import { Observable, Subject, throwError, of, BehaviorSubject } from 'rxjs';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private globals: Globals, private http: HttpClient, private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error: any) => {
      if (error instanceof HttpErrorResponse) {
        // throw 401 error response
        if (error.status === 401) {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            console.log('Token request');
            return this.http.post(environment.ApiURL + "Account/Refresh", {
              refreshToken: this.globals.getRefreshToken()
            }).pipe(
              switchMap((token: any) => {
                console.log('inside token');
                this.isRefreshing = false;
                if (token && token.accessToken && token.refreshToken) {
                  this.globals.setAccessToken(token.accessToken);
                  this.globals.setRefreshToken(token.refreshToken);
                }
                this.refreshTokenSubject.next(token);
                return next.handle(this.addHeader(request));
              }));
          } else {
            console.log('inside else call');
            console.log('token : ', this.refreshTokenSubject.getValue());
            return this.refreshTokenSubject.pipe(
              filter(token => (token !== null && token !== undefined)),
              take(1),
              switchMap(() => {
                console.log('adding header in else');
                return next.handle(this.addHeader(request));
              }));
          }
        }

        // throw 404 error response
        if (error.status === 404) {
          this.authService.logout();
        }

        // throw other error responses
        if (error.status === 0) {
          return throwError("There was an error connecting to API.");
        }
        const applicationError = error.headers.get("Application-Error");
        if (applicationError) {
          return throwError(applicationError);
        }
        const serverError = error.error;
        let modalStateErrors = "";
        if (serverError && typeof serverError === "object") {
          for (const key in serverError) {
            if (key === "errors") {
              for (const err in serverError[key]) {
                if (serverError[key]) {
                  modalStateErrors += error.error.errors[err][0] + "\n";
                }
              }
            } else {
              // if (serverError[key]) {
              //   modalStateErrors += serverError[key] + "\n";
              // }
            }
          }
        }
        return throwError(modalStateErrors || serverError || "Server Error");
      }
    }));
  }

  private addHeader(request: HttpRequest<any>) {
    const accessToken = this.globals.getAccessToken();
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return request;
  }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
